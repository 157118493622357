@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

:root {
  --main-blue: hsla(185, 100%, 35%, 1);
  --main-blue--dark: hsla(185, 95%, 30%, 1);
  --main-red: hsla(350, 73%, 35%, 1);
  --main-grey: hsl(0, 0%, 78%);
  --dark: hsla(0 0% 0% / 1);
  --light: hsla(0 0% 100% /1);
  --material-font: "Material Icons Round";
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-bold: 700;
  --radius-default: 1.2rem;
  --z-index-base: 333;
  --z-index-mid: 666;
  --z-index-top: 999;
}
*,
*::before,
*::after {
  position: relative;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: var(--light);
  color: var(--dark);
  accent-color: var(--main-blue);
  word-break: break-word;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-blue);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--main-blue--dark);
}
/* end SCROLLBAR */

/* LOADER */
.loader {
  width: 100vw;
  height: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader img {
  width: 100%;
  max-width: 100px;
  height: auto;
}
/* end LOADER */

/* TYPOGRAPHY */
h1,
h2,
h3,
h4 {
  text-transform: uppercase;
}

h1,
h2 {
  /*font-size: 2.250em;*/
  font-size: 3.125em;
  font-weight: var(--fw-bold);
  letter-spacing: 0.4rem;
}

h2,
h3,
h4 {
  color: var(--main-blue);
}

h1 > strong,
h1 > span,
h2 > strong,
h2 > span {
  font-weight: var(--fw-light);
}

h2 > strong,
h2 > span {
  color: var(--dark);
  display: inline-block;
}

h3 {
  font-size: 1.875em;
  font-weight: var(--fw-medium);
}

h4 {
  font-size: 1.25em;
  font-weight: var(--fw-bold);
}

.subtitle {
  text-transform: uppercase;
  font-size: 1.875em;
}

h1 + .subtitle,
.subtitle + h1,
h2 + .subtitle,
.subtitle + h2,
h3 + .subtitle,
.subtitle + h3,
h4 + .subtitle,
.subtitle + h4 {
  margin-top: -3rem;
}
a:link {
  color: var(--main-blue);
  text-decoration: none;
  font-weight: var(--fw-regular);
}

strong a:link {
  font-weight: var(--fw-bold);
}

a:visited {
  color: var(--main-blue--dark);
}

a:hover,
a:focus,
a:active {
  text-decoration: underline;
}

a:active {
  color: var(--dark);
}

p {
  font-weight: var(--fw-light);
}

p strong {
  font-weight: var(--fw-medium);
}

p,
.row > p {
  padding: 1rem 0;
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

p + p {
  margin-top: 0;
  padding-top: 0;
}

div > p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.material-icons-round {
  font-family: var(--material-font);
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

/* end TYPOGRAPHY */

img {
  width: 100%;
  height: auto;
}

button:not(.toggle),
.pill-btn {
  padding: 0.4rem 1rem;
  border-radius: var(--radius-default);
  display: inline-block;
}

.previous-btn:hover span {
  text-decoration: none !important;
}

.previous-btn {
  width: fit-content;
}
.previous-btn span {
  position: absolute;
  left: 0;
  transform: translateX(-100%);
}

/* back to top */
#BTT-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: var(--z-index-mid);
}

#BTT-btn button {
  margin: 2rem 1rem;
  background-color: var(--main-red);
  color: var(--light);
  border: 1px solid var(--main-red);
  font-family: var(--material-font);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 100%;
}

#BTT-btn button:visited {
  background-color: var(--main-red);
}
#BTT-btn button:hover,
#BTT-btn button:focus {
  background-color: hsla(350, 83%, 55%, 1);
  border-color: hsla(350, 83%, 55%, 1);
}
#BTT-btn button:active {
  background-color: var(--light);
  color: hsla(350, 83%, 55%, 1);
}
#BTT-btn button:active span {
  color: hsla(350, 83%, 55%, 1);
}

/* end back to top */

/* FORMS */

input:not([type="checkbox"]),
textarea {
  border: 1px solid var(--main-blue);
  border-radius: var(--radius-default);
  padding: 0.4rem 1rem;
  width: 100%;
}

input + input,
input + textarea,
textarea + input {
  margin-top: 1rem;
}

button,
.btn-success,
.pill-btn,
.pill-btn:link,
input[type="submit"] {
  background-color: var(--main-blue);
  color: var(--light);
  text-decoration: none;
  box-sizing: border-box;
  border: 1px solid var(--main-blue);
  border-radius: var(--radius-default);
  width: auto;
  text-align: center;
}

input[type="submit"] {
  min-width: 240px;
}

button:visited,
.btn-success:visited,
.pill-btn:visited,
input[type="submit"]:visited {
  background-color: hsla(185, 40%, 55%, 1);
  border: 1px solid hsla(185, 40%, 55%, 1);
  color: var(--light);
}

button:hover,
button:focus,
.btn-success:hover,
.btn-success:focus,
.pill-btn:hover,
.pill-btn:focus,
input[type="submit"]:hover,
input[type="submit"]:focus {
  background-color: hsla(185, 100%, 40%, 1);
  border: 1px solid hsla(185, 100%, 40%, 1);
  text-decoration: underline;
  color: var(--light);
}

button:active,
.btn-success:active,
.show > .btn-success.dropdown-toggle,
.pill-btn:active,
input[type="submit"]:active {
  background-color: var(--light);
  border: 1px solid var(--main-blue);
  color: var(--main-blue);
}
/* end FORMS */

.dark-blue-bg {
  background: var(--main-blue--dark);
}
.blue-bg {
  background: var(--main-blue);
}

.dark-blue-bg *,
.blue-bg * {
  color: var(--light);
}

.dark-blue-bg a:link,
.blue-bg a:link {
  color: var(--light);
  text-decoration: underline;
}

.dark-blue-bg a:visited,
.blue-bg a:visited {
  color: var(--main-grey);
}

.dark-blue-bg a:hover,
.blue-bg a:hover,
.dark-blue-bg a:focus,
.blue-bg a:focus {
  color: hsla(185, 100%, 75%, 1);
}

.dark-blue-bg a:active,
.blue-bg a:active {
  color: hsla(185, 100%, 75%, 1);
  text-decoration: none;
}

.dark-blue-bg button,
.blue-bg button,
.dark-blue-bg .pill-btn:link,
.blue-bg .pill-btn:link,
.dark-blue-bg input[type="submit"],
.blue-bg input[type="submit"] {
  background: none;
  border: 1px solid var(--light);
  text-decoration: none;
}

.dark-blue-bg button:visited,
.blue-bg button:visited,
.dark-blue-bg .pill-btn:visited,
.blue-bg .pill-btn:visited,
.dark-blue-bg input[type="submit"]:visited,
.blue-bg input[type="submit"]:visited {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--light);
  border-color: var(--light);
}

.dark-blue-bg button:hover,
.blue-bg button:hover,
.dark-blue-bg .pill-btn:hover,
.blue-bg .pill-btn:hover,
.dark-blue-bg input[type="submit"]:hover,
.blue-bg input[type="submit"]:hover,
.dark-blue-bg button:focus,
.blue-bg button:focus,
.dark-blue-bg .pill-btn:focus,
.blue-bg .pill-btn:focus,
.dark-blue-bg input[type="submit"]:focus,
.blue-bg input[type="submit"]:focus {
  background-color: rgba(0, 0, 0, 0.2);
  color: var(--light);
}

.dark-blue-bg button:active,
.blue-bg button:active,
.dark-blue-bg .pill-btn:active,
.blue-bg .pill-btn:active,
.dark-blue-bg input[type="submit"]:active,
.blue-bg input[type="submit"]:active {
  background-color: var(--light);
  color: var(--main-blue);
}

.row {
  --bs-gutter-x: 0;
}
/** -- HEADER -- **/
header {
  position: sticky;
  top: 0;
  z-index: var(--z-index-mid);
  background: var(--light);
}

.header {
  align-items: center;
}

.header,
.home-page .valores-block,
.equipa-block .carousel-container,
.servicos-intro-img,
.servicos-list > div,
.sabia-single > div {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.header > div {
  padding: 1rem;
}

.header > div > div {
  padding: 0;
}

.header > div + div {
  margin-top: 0;
}

header .logo-block {
  max-width: 100%;
}

header:not(.bg-white) .logo-block {
  max-height: 100%;
}

.header .logo-block img {
  width: 100%;
  max-width: 360px;
}

header.bg-white .logo-block {
  max-height: 50px;
  overflow: hidden;
  padding-bottom: 0;
}

.logo-block a {
  display: block;
}

/* NAV */
nav.navbar {
  flex-direction: column;
  z-index: var(--z-index-top);
  padding: 0;
}
nav .toggle {
  background: none;
  border: 0;
  color: var(--dark);
  padding: 0.3rem;
  min-height: 50px;
}
nav .toggle:visited {
  color: var(--dark);
}
nav .toggle:hover,
nav .toggle:focus {
  color: var(--main-blue--dark);
  text-decoration: none;
}
nav .toggle:active {
  color: var(--main-blue);
}

nav .toggle::before {
  content: "menu";
  font-family: var(--material-font);
  font-size: 2em;
  display: block;
}

nav .toggle.active::before {
  content: "close";
  font-family: var(--material-font);
  font-size: 2em;
  display: block;
}

nav .navbar-nav {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100vh - 100px);
  max-height: 0;
  overflow: hidden;
}

/* end NAV */

/** -- end HEADER -- **/

/** -- BODY -- **/

#innerBody > .container {
  padding: 0;
  max-width: 100%;
}
.full-width {
  width: 100%;
}
#body > .container > div:not(.full-width) {
  margin: 1rem;
}

#innerBody > .container > div:not(.full-width) {
  max-width: 1500px;
  margin: 0 auto;
}

#innerBody > .container > div:not(.full-width),
.full-width > div,
.full-width h2 {
  padding: 2rem;
}

#innerBody > .container > div:first-child {
  padding-top: 4rem;
}

#innerBody > .container > div:last-child {
  padding-bottom: 4rem;
}

.banner-block {
  min-height: 520px;
  height: 65vh;
  max-height: 720px;
  background-size: cover;
  background-position: center center;
}

.banner-block h1 {
  padding: 0 2rem;
}

.banner-block::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255 255 255 / 0.8) 0%,
    transparent 10%,
    transparent 90%,
    rgba(255 255 255 / 0.8) 100%
  );
}
/** -- end BODY -- **/

/** -- FOOTER -- **/
footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
footer .container {
  padding: 2rem;
  max-width: 100%;
}

footer .row {
  gap: 2rem;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

footer a {
  display: block;
}

footer ul,
.contactos-page ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

footer h4 + p {
  padding-top: 0;
}

footer .col p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

footer #fc-4 ul,
footer #fc-5 ul,
footer #fc-5 > p,
.phone ul,
.phone p,
.address ul {
  width: fit-content;
  padding-left: 1rem;
}

.phone p {
  margin: 0;
}

footer #fc-4 ul::before,
footer #fc-5 ul::before,
footer #fc-5 > p > a::before,
.phone ul::before,
.phone p a::before,
.address ul::before {
  font-family: var(--material-font);
  color: var(--light);
  position: absolute;
}

footer #fc-4 ul::before,
.address ul::before {
  content: "\e0c8";
  left: -0.2rem;
  top: -0.2rem;
}

footer #fc-5 ul::before,
.phone ul::before {
  content: "\e325";
  left: -0.2rem;
}

footer #fc-5 > p > a::before,
.phone p a::before {
  content: "\e0be";
  left: -1.4rem;
}

footer #fc-4 ul li,
footer #fc-5 ul li {
  text-align: left;
}

footer #fc-5 em {
    font-style: normal;
    font-size: .8em;
    display: inline-block;
}
footer .social-list ul {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  gap: 1rem;
  height: 100%;
}

footer .social-list ul li {
  width: 100%;
  max-width: fit-content;
}

footer .social-list ul li:not(:first-of-type) {
  max-width: 1.6rem;
  max-height: 1.6rem;
}

footer .social-list ul h4 {
  margin: 0;
}

footer .social-list ul img {
  width: 100%;
  max-width: 1.6rem;
  display: block;
}

footer .social-list a:hover img,
footer .social-list a:focus img {
  transform: scale(1.2);
}

footer .social-list a:active img {
  transform: scale(0.8);
}

#TERASTUDIO-brand {
  width: 100%;
  max-width: 150px;
  margin: 1rem;
  margin-bottom: 0;
}
/** -- end FOOTER -- **/

/** -- HOMEPAGE -- **/
h1,
h2 {
  text-align: center;
}

/* bloco slider */
.intro-slider .carousel {
  min-height: 50vh;
  max-height: 100vh;
}
.intro-slider .carousel-inner,
.intro-slider .carousel-item {
  height: 100%;
}
.intro-slider .carousel-item img {
  object-fit: cover;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.intro-slider .myInnerSliderData {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.intro-slider h1 {
  /*font-size: 90%;*/
  font-size: 1.875em;
  font-weight: var(--fw-regular);
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 2rem;
}

.intro-slider h1 > strong {
  font-weight: var(--fw-bold);
  font-size: 1.25em;
  display: inline-block;
}
/* end bloco slider */

/* bloco Valores */
.valores-block img {
  max-width: 180px;
}

.valores-block strong {
  display: block;
  font-size: 1.875em;
}
/* end bloco Valores */

/* bloco Serviços */
.servicos-block > .row {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.servicos-block ul {
  list-style: none;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  gap: 2rem;
  max-width: 1500px;
  margin: 0 auto;
}
.servicos-block ul li {
  width: 100%;
  text-align: center;
}

.servicos-block h4 {
  margin-top: 1rem;
}

.servicos-block img {
  max-width: 128px;
}
/* end bloco Serviços */
.testemunhos-block::before {
  content: "";
  background: url("assets/quotes.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: auto;
  width: 100%;
  height: 50px;
  margin-bottom: 1rem;
}
.testemunhos-block .react-multi-carousel-list::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, white 0, transparent 5%),
    linear-gradient(90deg, transparent 95%, white 100%);
}
.testemunhos-block .react-multi-carousel-track li {
  padding: 2rem 1rem;
}

.testemunhos-block .react-multi-carousel-item {
  max-width: 420px;
}

.testemunhos-block .card {
  border: 0;
}

.testemunhos-block .card .testemunho-txt {
  border: 1px solid var(--main-blue);
  border-radius: var(--radius-default);
  padding: 2rem 1rem;
}

.testemunhos-block .card .testemunho-foto {
  display: flex;
  align-items: center;
  /*overflow: hidden;*/
  text-align: center;
  background-color: var(--main-grey);
  border-radius: 100%;
  width: 60px;
  height: 60px;
  font-weight: var(--fw-bold);
  color: var(--light);
  margin: auto;
  z-index: 1;
}

.testemunho-foto::before,
.testemunho-foto::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--main-blue);
  position: absolute;
  top: -0.5rem;
  transform: translateY(-8px);
  left: 34.3%;
}

.testemunho-foto::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--light);
  transform: translateY(-9px);
}

.testemunhos-block .card .testemunho-nome {
  font-weight: var(--fw-bold);
  color: var(--main-blue);
  text-align: center;
}

.testemunhos-block .react-multi-carousel-dot-list {
    z-index: 1;
}

/* SABIA QUE */
.sabia-block .sabia-single {
  background-color: var(--main-grey);
  color: var(--light);
  font-weight: var(--fw-medium);
  background-position: left center;
  background-size: cover;
  font-size: 2.25em;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.sabia-single:nth-of-type(odd) {
  background-position: right center;
}

.sabia-single::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--main-blue--dark);
  opacity: 0.2;
}

.sabia-block p {
  padding: 0;
}

.sabia-block .sabia-single strong {
  font-weight: var(--fw-bold);
  text-transform: uppercase;
}
/* end SABIA QUE */
/* SOBRE */
.sobre-block h2 {
  text-align: left;
  margin-bottom: 2rem;
}
/* end SOBRE */

/** -- end HOMEPAGE -- **/

/** -- SOBRE -- **/
/*.sobre-page*/
.img-block {
  padding: 2rem 2rem 0 0;
}
/*.sobre-page*/
.img-block::before {
  content: "";
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("assets/bg-circle.svg");
  background-repeat: space;
}
/*.sobre-page*/
.img-block img {
  max-width: 600px;
}

.text-block h2 {
  text-align: left;
}
.text-block h2 > strong {
  font-size: 0.5em;
}

.equipa-block .half-bg {
  background-image: linear-gradient(
    var(--main-blue) 0%,
    var(--main-blue) 50%,
    var(--light) 50.1%,
    var(--light) 100%
  );
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.equipa-block .react-multi-carousel-track {
  padding-bottom: 2rem;
}
.equipa-block .react-multi-carousel-list {
  padding-bottom: 2rem;
}

.equipa-block .carousel-container .react-multi-carousel-item .card {
  max-width: 400px;
  border: 0;
  border-radius: var(--radius-default);
  text-align: center;
  background: none;
}

.equipa-block .carousel-container .react-multi-carousel-item .card img {
  background-color: var(--main-grey);
  border-radius: var(--radius-default);
  max-width: 390px;
  max-height: 390px;
  margin-left: auto;
  margin-right: auto;
}

.equipa-block .carousel-container .react-multi-carousel-item .card p {
  color: var(--main-blue);
  width: 100%;
  margin: 0;
  padding-bottom: 0;
}

.react-multi-carousel-dot-list {
  bottom: 0;
}

.react-multi-carousel-dot-list li {
  width: fit-content;
  padding: 0 0.5rem;
}

.react-multi-carousel-dot-list li button {
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: 1px solid var(--main-blue);
  background-color: var(--light);
}

.react-multi-carousel-dot-list li.react-multi-carousel-dot--active button {
  background-color: var(--main-blue);
}

/** -- end SOBRE -- **/

/** -- Serviços -- **/

.servicos-intro {
  padding-top: 0 !important;
}

.servicos-intro .blue-bg {
  padding: 4rem;
}

.servicos-intro p {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  max-width: 860px;
}
.servicos-intro-img {
  gap: 1rem;
  margin-top: calc(-33vw / 2 - 2rem);
}

.servicos-intro-img img {
  border-radius: var(--radius-default);
  max-width: 400px;
}
.servicos-list {
  background-image: url("assets/bg_servicos.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  gap: 4rem;
}

.servicos-list > .row:first-of-type {
  gap: 2rem;
  justify-content: center;
  width: 100%;
}
.servico-item {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  gap: 1rem;
  max-width: 400px;
}
.servico-item p {
  padding: 0;
  text-align: center;
}
.servico-icon {
  max-width: 128px;
}
/** -- end Serviços -- **/

/** -- CASOS CLÍNICOS -- **/
.casos-filtro-block .dropdown button {
  width: 100%;
}

.casos-filtro-block .btn-success[aria-expanded="true"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-decoration: none;
}

.casos-filtro-block .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(180deg);
}
.casos-filtro-block .dropdown-menu.show {
  width: 100%;
  border-bottom-left-radius: var(--radius-default);
  border-bottom-right-radius: var(--radius-default);
  padding-bottom: 1rem;
  border-color: var(--main-blue);
}

.casos-filtro-block .dropdown-menu .dropdown-item:link,
.casos-filtro-block .dropdown-menu .dropdown-item:visited,
.casos-filtro-block .dropdown-menu .dropdown-item:hover,
.casos-filtro-block .dropdown-menu .dropdown-item:focus,
.casos-filtro-block .dropdown-menu .dropdown-item:active {
  background: none;
}

.casos-filtro-block .lista-casos {
  list-style: none;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0;
}

.casos-filtro-block .lista-casos li:not(.active) button {
  background: none;
  color: var(--dark);
  border: 0;
}

/* .casos-filtro-block .lista-casos button > span {
    padding-right: 1rem;
} */

.casos-filtro-block .lista-casos button .material-icons-round {
  position: absolute;
  bottom: -0.15rem;
}

.casos-list-block {
  padding-bottom: 0 !important;
}
.casos-list-block > div {
  padding: 0;
}

.casos-list-block .single-post {
  padding-bottom: 0;
}
.casos-list-block > div:nth-of-type(odd) .single-post {
  flex-direction: row-reverse;
}

.single-post .post-info {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.single-post img {
  object-fit: cover;
  height: 100%;
}

.single-post .post-info .pill-btn {
  width: fit-content;
  min-width: 240px;
  margin-bottom: 2rem;
}
/** -- end CASOS CLÍNICOS -- **/

/** -- CONTACTOS -- **/

.contactos-page ul::before,
.contactos-page p a::before {
  color: var(--main-blue);
}

.contactos-page div + h4,
.contactos-form {
  margin-top: 2rem;
}

.contactos-form div + div {
  margin-top: 1rem;
}

.contactos-form .d-flex {
  gap: 1rem;
}

.contactos-form .d-flex input {
  margin-top: 0;
}

.contactos-form .rgdp-block.d-flex {
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 0.6rem;
}

.contactos-form .rgdp-block.d-flex input {
  margin-top: 0.2rem;
}

.contactos-form input[type="submit"] {
  margin-left: auto;
  margin-right: auto;
}

/** -- end CONTACTOS -- **/

/** -- MEDIA QUERIES -- **/
@media screen and (max-width: 767px) {
  .nav-block {
    max-width: fit-content;
  }

  nav .navbar-nav.open {
    position: fixed;
    max-height: 100%;
  }

  header:not(.bg-white) nav .navbar-nav.open {
    top: 200px;
  }

  header.bg-white nav .navbar-nav.open {
    top: 140px;
  }

  nav .navbar-nav.open ul {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2rem;
    top: 0;
    left: 0;
    background-color: var(--main-blue--dark);
    display: flex;
    list-style: none;
    flex-direction: column;
  }

  nav .navbar-nav.active .nav-link:link {
    color: var(--light);
    font-size: 1.875em;
    font-weight: var(--fw-medium);
  }

  nav .navbar-nav.active .nav-link:not(:hover):not(:focus):not(:active) {
    text-decoration: none;
  }

  nav .navbar-nav.active .nav-link:visited {
    color: var(--main-grey);
  }

  nav .navbar-nav.active .nav-link:hover,
  nav .navbar-nav.active .nav-link:focus,
  nav .navbar-nav.active .nav-link:active {
    color: var(--light);
  }

  nav .navbar-nav.active .nav-link.active {
    background-color: rgba(255, 255, 255, 0.2);
    color: var(--light);
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2.5rem;
  }

  .casos-filtro-block {
    padding-top: 2rem !important;
  }

  .contactos-page .contactos-info {
    text-align: center;
  }

  .contactos-page .contactos-info ul,
  .contactos-page .contactos-info p {
    margin: 0 auto;
  }

  .contactos-form .d-flex {
    flex-flow: column wrap;
  }

  footer > .container > .row {
    flex-flow: column wrap;
    justify-content: center;
    text-align: center;
  }

  footer > .container + .container {
    padding-top: 0;
  }

  footer #fc-4 ul,
  footer #fc-5 ul {
    margin: 0 auto;
  }

  footer .social-list ul {
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  /*h1,
    h2 {
        font-size: 3.125em;
        letter-spacing: .6rem;
    }*/

  /* NAV */
  .toggle {
    display: none;
  }
  nav .navbar-nav {
    max-height: unset;
    background: none;
    position: relative;
    height: auto;
    width: 100%;
  }
  nav .navbar-nav ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
  }

  .header .btn-agendar {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(calc(-100% - 2rem));
  }

  nav.navbar-light .navbar-nav .nav-link.active:not(:hover):not(:active),
  nav.navbar-light .navbar-nav .nav-link:link:not(:hover):not(:active) {
    text-decoration: none;
  }
  nav.navbar-light .navbar-nav .nav-link.active,
  nav.navbar-light .navbar-nav .nav-link.active:visited {
    color: var(--main-blue);
  }
  nav.navbar-light .navbar-nav .nav-link:visited {
    color: var(--dark);
  }
  nav.navbar-light .navbar-nav .nav-link:hover,
  nav.navbar-light .navbar-nav .nav-link:focus,
  nav.navbar-light .navbar-nav .nav-link:active {
    color: var(--main-blue);
  }
  /* end NAV */

  /* HOMEPAGE */

  .intro-slider .myInnerSliderData {
    min-height: 100vh;
    padding-bottom: 90px;
  }

  .intro-slider h1 {
    max-width: 75%;
  }

  .valores-block.full-width {
    flex-direction: row;
    width: calc(100% - 4rem);
    border-radius: var(--radius-default);
    padding-top: 0 !important;
    z-index: var(--z-index-base);
    bottom: 50px;
    margin-bottom: -50px;
  }
  .valores-block > div {
    width: 33.333%;
    margin-bottom: calc(-90px - 2rem);
    bottom: calc(90px + 2rem);
  }
  .servicos-block .blue-bg {
    background-image: url("assets/hp-servicos-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .servicos-block ul {
    flex-direction: row;
    padding-top: 100px;
    gap: 4rem;
    top: 2rem;
  }

  .servicos-block .blue-bg li {
    max-width: calc(50% - 2rem);
    padding-right: 25%;
  }

  .servicos-block .blue-bg li:nth-child(even) {
    padding-right: 0;
    padding-left: 25%;
  }

  .servicos-block .blue-bg li:nth-last-of-type(-n + 3) {
    margin-top: 160px;
    padding: 0;
    max-width: calc(32.5% - 2rem);
  }

  /* end HOMEPAGE */

  .servico-item {
    max-width: calc(50% - 1rem);
  }

  /*.sobre-page*/
  .img-block,
    /*.sobre-page*/ .text-block {
    padding: 4rem;
  }

  .img-block::before {
    width: calc(100% - 8rem);
    height: calc(100% - 8rem);
  }

  footer p {
    margin: 0;
  }

  footer .powered-by {
    display: flex;
    flex-flow: column wrap;
    text-align: left;
    align-content: flex-end;
    padding-right: calc(64px + 1rem);
  }

  #TERASTUDIO-brand {
    margin: 0;
  }
}

@media screen and (max-width: 991px) {
  .servicos-intro-img {
    display: none;
  }
  .text-block {
    margin-bottom: 2rem;
  }
  .sobre-page .reversed-col {
    flex-direction: column-reverse;
    padding-top: 0 !important;
  }
}
@media screen and (min-width: 992px) {
  header {
    background-color: rgba(255 255 255 / 0.2);
    backdrop-filter: blur(5px);
    position: fixed;
    width: 100%;
  }
  nav .navbar-nav ul {
    margin-bottom: 0;
  }
  .header .btn-agendar {
    position: relative;
    transform: none;
  }

  /* HOMEPAGE */

  .intro-slider h1 {
    font-size: 2.5em;
    margin-bottom: 2rem;
  }

  .valores-block.full-width {
    bottom: 90px;
    margin-bottom: calc(-90px + 2rem);
  }

  #innerBody > .container > div.testemunhos-block {
    padding-top: 4rem;
  }

  .testemunhos-block::before {
    content: none;
  }

  .testemunhos-block h2 {
    margin-top: 50px;
    padding-left: 100px;
    padding-right: 100px;
  }

  .testemunhos-block h2::before,
  .testemunhos-block h2::after {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }

  .testemunhos-block h2::before {
    content: url("assets/quotes.svg");
    left: 0;
  }

  .testemunhos-block h2::after {
    content: url("assets/quotes.svg");
    right: 0;
    transform: rotate(180deg);
    transform-origin: 50% 25%;
  }

  .sabia-single p {
    margin: 0;
  }

  .sabia-single:nth-of-type(odd) p {
    float: right;
  }
  /* end HOMEPAGE */

  .servicos-intro .blue-bg {
    padding-bottom: calc(33vw / 2 + 2rem);
  }

  .servico-item {
    max-width: calc(33.33333333% - 2rem);
  }

  .servicos-list > .row:first-of-type {
    justify-content: space-between;
  }

  #footer-top > .row {
    justify-content: space-between;
  }

  #footer-top > .row > .col {
    max-width: fit-content;
  }
}

@media screen and (min-width: 1024px) {
  .servicos-block ul {
    padding-top: 50px;
  }

  .servicos-block li:nth-child(7) {
    left: 200px !important;
  }

  .servicos-block li:nth-child(8) {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .servicos-block li:last-child {
    right: 200px !important;
  }
}

@media screen and (min-width: 1400px) {
  div > .text-block:first-child,
  div > .img-block:first-child {
    padding-left: 0;
  }

  .img-block + .text-block {
    padding-right: 0;
  }

  div > .img-block:first-child::before {
    left: 4rem;
  }
}

/** -- end MEDIA QUERIES -- **/
